//Преобразование диапазона дат в строку, которая уйдет в параметры URL
//[2022-01-02, 2022-04-02] ---> '2022-01-02,2022-04-02'
export function serializeDateRange(dates){
    return dates[0]+','+dates[1]
}

//Преобразование диапазона дат из параметров URL в массив из двух дат, который поймет компонент RangeDateField
//'2022-01-02,2022-04-02' ---> [2022-01-02, 2022-04-02]
export function deserializeDateRange(datesStr){
    if(datesStr == null)
        return null
    let strs = datesStr.split(',')
    return [strs[0], strs[1]]
}

//Допустим, справочник задан объектом: lsStates: {draft: 'Черновик',  onWay: 'В пути', ...
//эта функция преобразует такой справочник-объект в массив [{code: 'draft', value: 'Черновик}, {code: 'onWay', value: ...]
//такой массив может передаваться на вход компоненту v-select
export function objectToDict(dictObj){
    let res = []
    for(let entry in dictObj)
        res.push({
            code: entry,
            value: dictObj[entry]
        })
    return res
}

//Таймстэмп в человекочитаемую строку (дату)
export function formatTimestamp(dateStr) {
    let dt = new Date(+dateStr)
    let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
    return dt.toLocaleDateString('ru-RU', options)
}

//Дату в привычный российский формат
export function formatDate(dateStr) {
    let dt = new Date(dateStr)
    let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
    return dt.toLocaleDateString('ru-RU', options)
}

//Дату и время в привычный российский формат
export function formatDateTime(dateStr) {
    let dt = new Date(dateStr)
    let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'}
    return dt.toLocaleDateString('ru-RU', options)
}

//2022-01-02 => unix timestamp со временем 00:00:00.000
export function dateStrToLowerTimestamp(dateStr) {
    let dt = new Date(dateStr)
    return dt.getTime()
}

//2022-01-02 => unix timestamp со временем 23:59:59.999
export function dateStrToUpperTimestamp(dateStr) {
    let dt = new Date(dateStr+'T23:59:59.999')
    return dt.getTime()
}

export function hashCode(str) {
    let hash = 0, i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
        chr   = str.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}

export function changeRadix(num) {
    //let alphabet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ~!@#$%^&*()-+=/.,:;\\|<>бгдёжзилпфцчшщъэюяБГДЁЖЗИЛПФЦЧШЩЪЭЮЯ'
    //let alphabet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-'
    let alphabet = '0123456789ABCDEF'
    let radix = alphabet.length
    let res = ''
    for (let e = 7; e >= 0; e--) {
        let c = Math.pow(radix, e)
        let digit = Math.floor(num / c);
        if (res.length != 0 || digit != 0)
            res += alphabet[digit]
        num = num % c
    }
    return res
}

export function generateSessionId(idTokenParsed){
    let hash = changeRadix(Math.abs(hashCode(idTokenParsed.sid)))
    if(hash.length > 3)
        return hash.slice(0, 3) + "-" + hash.slice(3);
    return hash
}

export function debounce(f, ms) {
    let isCooldown = false;
    return function () {
        if (isCooldown) return;
        f.apply(this, arguments);
        isCooldown = true;
        setTimeout(() => isCooldown = false, ms);
    };
}

export function truncateStr(str, n) {
    if(!str) return str
    if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
    }
    return str
}

export function floor2(val) {
    if (!val) val = 0
    return Math.floor(val * 100) / 100
}
