export const periodSpans = [
    'YEAR', // 2022 - 2023 - 2024
    'YEAR_HALF_YEAR', // 2022_H1, 2022, 2023_H1, 2023, 2024H1, 2024
    'QUARTER' // 2023_H1_Q1, 2023_H1_Q2, 2023_H2_Q3, 2023_H2_Q4, 2024_H1_Q1
]

const MIN_YEAR = 1900
const MAX_YEAR = 2100

function isIntegerBetween(n, minN, maxN){
    if(!Number.isInteger(minN) || !Number.isInteger(maxN)){
        throw new Error('minN and maxN must be integer numbers')
    }
    if(!Number.isInteger(n)){
        return false
    }
    return n >= minN && n <= maxN
}

export class Period {
    constructor(year, halfYear, quarter){
        this.year = +year
        this.halfYear = halfYear ? +halfYear : null
        this.quarter = quarter ? +quarter : null
        if(!this.validate()){
            throw new Error(`Invalid year (${year}), half-year (${halfYear}) and quarter (${quarter}) combination`)
        }
    }

    validate(){
        const y = this.year
        const h = this.halfYear
        const q = this.quarter
        if(!isIntegerBetween(y, MIN_YEAR, MAX_YEAR)){
            return false
        }
        if(h !== null &&
            (!isIntegerBetween(h, 1, 2))){
            return false
        }
        if(q !== null){
            if(h == null ||
                !isIntegerBetween(q, 1, 4)){
                return false
            }
            if(!(h === 1 && q === 1) && !(h === 1 && q === 2) && !(h === 2 && q === 3) && !(h === 2 && q === 4)){
                return false
            }
        }
        return true
    }

    nextYear(){
        this.year = this.year + 1
    }

    prevYear(){
        this.year = this.year - 1
    }

    nextYearHalfYear(){
        if(this.quarter != null){
            throw new Error('Quarter is not null, can\'t change halfYear')
        }
        if(this.halfYear === 2){
            throw new Error('Invalid halfYear=2 for mode yearHalfYear ')
        }
        if(this.halfYear === 1){
            this.halfYear = undefined
        } else if(this.halfYear == null){
            this.year++
            this.halfYear = 1
        }
    }

    prevYearHalfYear(){
        if(this.quarter != null){
            throw new Error('Quarter is not null, can\'t change halfYear')
        }
        if(this.halfYear === 2){
            throw new Error('Invalid halfYear=2 for mode yearHalfYear ')
        }
        if(this.halfYear === 1){
            this.year--
            this.halfYear = undefined
        } else if(this.halfYear == null){
            this.halfYear = 1
        }
    }

    nextQuarter(){
        if(this.quarter === 1 || this.quarter === 3){
            this.quarter++
        } else if(this.quarter === 2){
            this.quarter++
            this.halfYear = 2
        } else if(this.quarter === 4){
            this.year++
            this.halfYear = 1
            this.quarter = 1
        } else {
            throw new Error(`Current quarter is undefined or invalid: ${this.quarter}`)
        }
    }

    prevQuarter(){
        if(this.quarter === 2 || this.quarter === 4){
            this.quarter--
        } else if(this.quarter === 3){
            this.quarter--
            this.halfYear = 1
        } else if(this.quarter === 1){
            this.year--
            this.halfYear = 2
            this.quarter = 4
        } else {
            throw new Error(`Current quarter is undefined or invalid: ${this.quarter}`)
        }
    }

    toString(){
        let res = this.year.toString()
        if(this.halfYear){
            res += '_H' + this.halfYear
        }
        if(this.quarter){
            res += '_Q' + this.quarter
        }
        return res
    }

    static ofString(periodCode){
        if(periodCode == null){
            throw new Error('periodCode must not be null')
        }
        periodCode = periodCode.toString()
        if(periodCode.length === 0){
            throw new Error('periodCode must not be empty')
        }
        const parts = periodCode.split('_')
        if (parts.length === 1) {
            return new Period(parts[0])
        }
        if (!parts[1].startsWith('H')) {
            throw new Error('Incorrect periodCode ' + periodCode)
        }
        const halfYear = parts[1].substring(1)
        if(halfYear.length !== 1){
            throw new Error('Incorrect periodCode ' + periodCode)
        }
        if (parts.length === 2) {
            return new Period(parts[0], halfYear)
        }
        if (!parts[2].startsWith('Q')) {
            throw new Error('Incorrect periodCode ' + periodCode)
        }
        const quarter =  parts[2].substring(1)
        if(quarter.length !== 1){
            throw new Error('Incorrect periodCode ' + periodCode)
        }
        if (parts.length === 3){
            return new Period(parts[0], halfYear, quarter)
        }
        throw new Error('Incorrect periodCode ' + periodCode)
    }

    toDisplayName(){
        if(this.quarter != null){
            return `${this.quarter} квартал ${this.year} года`
        }
        if(this.halfYear != null){
            return `${this.halfYear} полугодие ${this.year} года`
        }
        return `${this.year} год`
    }

    compare(other) {
        if (!this.validate() || !other.validate()) {
            throw new Error('Invalid period')
        }
        if (this.year < other.year) return -1
        if (this.year > other.year) return 1
        // далее годы равные
        if (this.halfYear == null && other.halfYear == null) return 0
        if (other.halfYear == null) return -1
        if (this.halfYear == null) return 1
        if (this.halfYear < other.halfYear) return -1
        if (this.halfYear > other.halfYear) return 1
        // далее равные годы, равные полугодия, не равные null
        if (this.quarter == null && other.quarter == null) return 0
        if (other.quarter == null) return -1
        if (this.quarter == null) return  1
        if (this.quarter < other.quarter) return -1
        if (this.quarter > other.quarter) return 1
        return 0
    }

    // дата первого дня периода
    getStartDate(){
        let month
        if(this.quarter != null){
            month = this.quarter * 3 - 2
        } else if(this.halfYear) {
            month = this.halfYear * 6 - 5
        } else {
            month = 1
        }
        if(month < 10) {
            month = '0' + month
        }
        return this.year + '-' + month + '-01'
    }

    // дата последнего дня периода
    getEndDate(){
        let month, day
        if(this.quarter != null){
            switch (this.quarter){
                case 1:
                    month = 3
                    day = 31
                    break
                case 2:
                    month = 6
                    day = 30
                    break
                case 3:
                    month = 9
                    day = 30
                    break
                case 4:
                    month = 12
                    day = 31
                    break
            }
        } else if (this.halfYear === 1){
            month = 6
            day = 30
        }
        else {
            month = 12
            day = 31
        }
        if(month < 10) {
            month = '0' + month
        }
        return this.year + '-' + month + '-' + day
    }
}

/**
 * Период по дате
 * @param {Date} date - дата ( например, new Date('2024-02-12') )
 * @param {string} periodSpan - длина периода - см. константу periodSpans
 * @return {string|undefined}
 */
export function getPeriodByDate(date, periodSpan) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    if (periodSpan === 'YEAR') {
        return new Period(year).toString()
    }
    if (periodSpan === 'YEAR_HALF_YEAR') {
        if(month > 6){
            return new Period(year).toString()
        } else {
            return new Period(year, 1).toString(year)
        }
    }
    if (periodSpan === 'QUARTER') {
        const halfYear = month <= 6 ? 1 : 2
        const quarter = Math.ceil((month) / 3)
        return new Period(year, halfYear, quarter).toString()
    }
    throw new Error('Invalid period Span ' + periodSpan)
}


/**
 * Название периода по его коду
 * @param {string} period - код периода
 * @return {string}
 */
export function getPeriodName(period) {
    return Period.ofString(period).toDisplayName()
}

/**
 * Получить код следующего периода
 * @param {string} periodCode - код периода
 * @param {string} periodSpan - длина периода - см. константу periodSpans
 * @return {string}
 */
export function nextPeriod(periodCode, periodSpan) {
    let period = Period.ofString(periodCode)
    if (periodSpan === 'YEAR') {
        if(period.halfYear != null || period.quarter != null){
            throw new Error(`Incorrect input: periodCode ${periodCode} and periodSpan ${periodSpan}`)
        }
        period.nextYear()
        return period.toString()
    } else if (periodSpan === 'YEAR_HALF_YEAR') {
        period.nextYearHalfYear()
        return period.toString()
    } else if (periodSpan === 'QUARTER') {
        period.nextQuarter()
        return period.toString()
    }
    throw new Error(`Invalid periodSpan: ${periodSpan}`)
}

/**
 * Получить код предыдущего периода
 * @param {string} periodCode - код периода
 * @param {string} periodSpan - длина периода - см. константу periodSpans
 * @return {string}
 */
export function previousPeriod(periodCode, periodSpan) {
    let period = Period.ofString(periodCode)
    if (periodSpan === 'YEAR') {
        if(period.halfYear != null || period.quarter != null){
            throw new Error(`Incorrect input: periodCode ${periodCode} and periodSpan ${periodSpan}`)
        }
        period.prevYear()
        return period.toString()
    } else if (periodSpan === 'YEAR_HALF_YEAR') {
        period.prevYearHalfYear()
        return period.toString()
    } else if (periodSpan === 'QUARTER') {
        period.prevQuarter()
        return period.toString()
    }
    throw new Error(`Invalid periodSpan: ${periodSpan}`)
}

/**
 * Генерация массива периодов
 * @param startPeriod - код периода, с которого начать
 * @param depth - "глубина" следования. 0 - будет только startPeriod, 1 - startPeriod и следующий.
 * Если depth > 0, идем вперед, если depth < 0 - назад
 * @param periodSpan - длина периодов - см. periodSpans
 * @return {*[]} - массив периодов вида [{"code": "2022", "text": "2022 год"}, ...]
 */
export function generatePeriods(startPeriod, depth, periodSpan) {
    let generatedPeriods = []
    let iterPeriodFunction
    if (depth > 0) {
        iterPeriodFunction = nextPeriod
    } else {
        depth = -depth
        iterPeriodFunction = previousPeriod
    }
    let curPeriod = startPeriod
    for (let i = 0; i <= depth; i++) {
        generatedPeriods.push({
            value: curPeriod,
            text: getPeriodName(curPeriod)
        })
        curPeriod = iterPeriodFunction(curPeriod, periodSpan)
    }
    return generatedPeriods
}

/**
 * Генерация периодов между двумя периодами
 * @param startPeriodCode - период, с которого начать
 * @param endPeriodCode - период, на котором остановиться. может быть как > startPeriod, так и <
 * @param periodSpan - длина периодов - см. periodSpans
 * @return {*[]} - массив периодов вида [{"value": "2022", "text": "2022 год"}, ...]
 */
export function generatePeriodsBetween(startPeriodCode, endPeriodCode, periodSpan) {
    let generatedPeriods = []
    let iterPeriodFunction

    const startPeriod = Period.ofString(startPeriodCode)
    const endPeriod = Period.ofString(endPeriodCode)
    if (endPeriod.compare(startPeriod) >= 0) {
        iterPeriodFunction = nextPeriod
    } else {
        iterPeriodFunction = previousPeriod
    }
    let i = 0
    let curPeriod = startPeriodCode
    while (i < 100 && curPeriod !== endPeriodCode) {
        i++
        generatedPeriods.push({
            value: curPeriod,
            text: getPeriodName(curPeriod)
        })
        curPeriod = iterPeriodFunction(curPeriod, periodSpan)
    }
    generatedPeriods.push({
        value: curPeriod,
        text: getPeriodName(curPeriod)
    })
    return generatedPeriods
}


/**
 * Следующий период "по кругу".
 * nextPeriodRoundRobin('2022', '2020', '2022', false) = '2020'
 * nextPeriodRoundRobin('2022', '2020', '2022', true) = null
 * nextPeriodRoundRobin('2021', '2020', '2022', false) = '2022'
 * @param {string|null} periodCode - код периода, который нужно увеличить
 * @param {string} minPeriodCode - минимальный период, позади которого либо null, либо maxPeriod
 * @param {string} maxPeriodCode - максимальный период, после которого либо null, либо minPeriod
 * @param {string} periodSpan - длина периодов - см. periodSpans
 * @param {boolean} nullable - есть ли null после maxPeriod
 */
export function nextPeriodRoundRobin(periodCode, minPeriodCode, maxPeriodCode, periodSpan, nullable) {
    if (periodCode == null && !nullable) {
        throw new Error('null period when nullable is false')
    }
    const minPeriod = Period.ofString(minPeriodCode) // валидируем в этом месте
    const maxPeriod = Period.ofString(maxPeriodCode)
    if(minPeriod.compare(maxPeriod) > 0) {
        throw new Error('minPeriod > maxPeriod')
    }
    if (periodCode == null) {
        return minPeriod.toString()
    }

    let period = Period.ofString(periodCode)
    if(period.compare(minPeriod) < 0 || period.compare(maxPeriod) > 0){
        throw new Error('period is out of bounds')
    }
    if (!nullable && period.compare(maxPeriod) === 0) {
        return minPeriod.toString()
    }
    if (nullable && period.compare(maxPeriod) === 0) {
        return null
    }
    return nextPeriod(periodCode, periodSpan)
}

/**
 * Предыдущий период "по кругу".
 * previousPeriodRoundRobin('2022', '2020', '2022', false) = '2021'
 * previousPeriodRoundRobin('2020', '2020', '2022', true) = null
 * previousPeriodRoundRobin('2020', '2020', '2022', false) = '2022'
 * @param {string|null} periodCode - код периода, который нужно уменьшить
 * @param {string} minPeriodCode - минимальный период, позади которого либо null, либо maxPeriod
 * @param {string} maxPeriodCode - максимальный период, после которого либо null, либо minPeriod
 * @param {string} periodSpan - длина периодов - см. periodSpans
 * @param {boolean} nullable - есть ли null после maxPeriod
 */
export function previousPeriodRoundRobin(periodCode, minPeriodCode, maxPeriodCode, periodSpan, nullable) {
    if (periodCode == null && !nullable) {
        throw new Error('null period when nullable is false')
    }
    const minPeriod = Period.ofString(minPeriodCode) // валидируем в этом месте
    const maxPeriod = Period.ofString(maxPeriodCode)
    if(minPeriod.compare(maxPeriod) > 0) {
        throw new Error('minPeriod > maxPeriod')
    }
    if (periodCode == null) {
        return maxPeriod.toString()
    }

    let period = Period.ofString(periodCode)
    if(period.compare(minPeriod) < 0 || period.compare(maxPeriod) > 0){
        throw new Error('period is out of bounds')
    }
    if (!nullable && period.compare(minPeriod) === 0) {
        return maxPeriod.toString()
    }
    if (nullable && period.compare(minPeriod) === 0) {
        return null
    }
    return previousPeriod(periodCode, periodSpan)
}

export function getPeriodStartDate(periodCode){
    const period = Period.ofString(periodCode) // валидируем в этом месте
    return period.getStartDate()
}

export function getPeriodEndDate(periodCode){
    const period = Period.ofString(periodCode) // валидируем в этом месте
    return period.getEndDate()
}
