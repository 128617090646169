import api from "@/modules/api";

export async function getIndicatorsByDomain(domain){
    return api.get(`/indicators?domain=${domain}&size=10000&disabled=false`)
}

export async function createIndicator(indicator){
    return api.postJson(`/indicators`, indicator)
}

export async function getRegionIndicatorsByPeriod(domain, regionCode, periodCode, cznMode){
    return api.get(`/indicators/values/${domain}/region/${regionCode}${periodCode ? '/'+periodCode : ''}${cznMode ? '?cznMode=' + cznMode : ''}`)
}

export async function getRegionIndicatorsByContext(domain, regionCode, context){
    return api.get(`/indicators/values/${domain}/region/${regionCode}/context/${context}`)
}

export async function getRegionIndicatorsByIndicatorId(domain, regionCode, indicatorId){
    return api.get(`/indicators/values/${domain}/${indicatorId}/region/${regionCode}`)
}

export async function getCznIndicatorsByPeriod(domain, cznId, periodCode){
    return api.get(`/indicators/values/${domain}/czn/${cznId}${periodCode ? '/'+periodCode : ''}`)
}

export async function getCznIndicatorsByIndicatorId(domain, cznId, indicatorId){
    return api.get(`/indicators/values/${domain}/${indicatorId}/czn/${cznId}`)
}

export async function getIndicatorsByContext(domain, context){
    return api.get(`/indicators/values/${domain}/context/${context}`)
}

export async function getIndicatorsByContextAndIndicatorId(domain, context, indicatorId){
    return api.get(`/indicators/values/${domain}/${indicatorId}/context/${context}`)
}

export async function getIndicatorsByIndicatorFormId(indicatorFormId){
    return api.get(`/indicators/values/form/${indicatorFormId}`)
}

export async function getByDomainAndIndicatorId(domain, indicatorId, filter){
    return api.get(`/indicators/values/${domain}/${indicatorId}?${filter}`)
}

export async function saveIndicators(indicators){
    return api.postJson(`/indicators/values`, indicators)
}
