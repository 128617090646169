import api from "@/modules/api";

export async function uploadFile(file, maxSizeMb=50){
    if (file.size > maxSizeMb*1024*1024) {
        alert(`Файл больше ${maxSizeMb}Мб. Загрузите, пожалуйста, файл с меньшим размером`);
        return null
    }
    let formData = new FormData();
    formData.append('file', file);
    let req = await api.postFile('/files', {}, formData, 'POST');
    console.log('req', req)
    if (req.ok) {
        return req.payload
    } else {
        alert('Ошибка загрузки файла: ' + req.error)
        console.log('upload error', req.error)
    }
}

export async function downloadFile(fileId, fileName) {
    let req = await api.download("/files/" + fileId, {});
    if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
    } else {
        console.log('download error');
    }
}
